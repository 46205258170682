import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="May 2019" subnav="release-notes">
      <div id="May2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          We just can't seem to turn a blind eye to the web even as we build out
          the system for React Native. (Sorry, not sorry!) So while we released
          all sorts of buttons, we snuck in a new card component and even
          wrapped up about tabs.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Guidelines for using the{' '}
                <Link href="/components/buttons/floating-action/design">
                  floating action
                </Link>{' '}
                button.
              </Added>
              <Added>
                Explanation behind using{' '}
                <Link href="/components/buttons/button/design#Mobile">
                  buttons
                </Link>{' '}
                on mobile devices.
              </Added>
              <Added>
                <Link href="/components/card/design">Card</Link> guidelines for
                collecting and displaying all sorts of content.
              </Added>
              <Added>
                Everything you need to know for the new{' '}
                <Link href="/patterns/tabs">tabs</Link> pattern.
              </Added>
              <Added>
                Some handy keyboard shortcuts you can see at the bottom right.
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.30.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.30.0"
            />

            <div className={styles.changesList}>
              <Added>
                A new set of icons:
                <List>
                  <li>Back</li>
                  <li>Forward</li>
                  <li>Download</li>
                  <li>Upload</li>
                  <li>Livestream</li>
                  <li>Location</li>
                </List>
              </Added>
              <Added>
                That <Link href="/components/card/code">card</Link> component.
              </Added>
              <Improved>
                The icons for:
                <List>
                  <li>Exchanges</li>
                  <li>Log-out</li>
                  <li>Tag</li>
                  <li>Upload</li>
                </List>
              </Improved>
              <Fixed>
                The avatar from displaying incorrectly in Firefox on Windows
                machines. (Thanks, Sydney.)
              </Fixed>
              <Fixed>
                The checkbox from resizing when its label wrapped to another
                line. (Thanks again, Sydney!)
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              upgradeUrl="https://github.com/hudl/rn-uniform-ui/wiki/to-v7.0.0"
              version="7.0.0"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v7.0.0"
            />
            <div className={styles.changesList}>
              <Added>
                All the{' '}
                <Link href="/components/buttons/button/code">buttons</Link> you
                could ever need.
              </Added>
              <Added>
                A new{' '}
                <Link href="/components/buttons/floating-action/code">
                  floating action
                </Link>{' '}
                button, too.
              </Added>
              <Added>
                New <Link href="/components/icon/code">icons</Link> and{' '}
                <Link href="/guidelines/colors/utility/code">
                  utility text colors
                </Link>
                .
              </Added>
              <Added>
                The spinner for button statuses and other spinning things.
              </Added>
              <Improved>
                How props are provided for the{' '}
                <Link href="/components/icon/code">icon</Link> and{' '}
                <Link href="/components/notifications/toast/code">toast</Link>{' '}
                components.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>That floating action button.</Added>
              <Added>All those fancy icons we mentioned earlier.</Added>
              <Added>
                The new card component for light and dark environments.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
